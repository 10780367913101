<template>
  <div>
    <!-- 헤더 -->
    <div class="header">
      <h1>자유 게시판</h1>
      <div class="auth-menu">
        <button class="login-btn" @click="navigateTo('login.html')">로그인</button>
        <button class="signup-btn" @click="navigateTo('register.html')">회원가입</button>
      </div>
    </div>

    <!-- 게시판 -->
    <div class="board">
      <div class="search">
        <select v-model="searchType">
          <option value="title">제목 검색</option>
          <option value="content">내용 검색</option>
          <option value="author">작성자 검색</option>
          <option value="title-content">제목+내용 검색</option>
        </select>
        <input type="text" v-model="searchQuery" placeholder="검색" />
        <button class="search-btn" @click="handleSearch">검색</button>
      </div>

      <table class="post-list">
        <thead>
          <tr>
            <th>No</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성시간</th>
            <th>조회수</th>
            <th>좋아요</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="post in posts" :key="post.POST_ID">
            <td>{{ post.POST_ID }}</td>
            <td>
              <a href="#" class="post-link" @click.prevent="handlePostClick(post.POST_ID)">
                {{ post.TITLE }}
              </a>
            </td>
            <td>{{ post.USER_ID }}</td>
            <td>{{ formatDate(post.CREATED_AT) }}</td>
            <td>{{ post.VIEWS_CNT }}</td>
            <td>{{ post.LIKES_CNT }}</td>
          </tr>
        </tbody>
      </table>

      <!--<div class="page-nav">
        <a href="#" class="prev" @click.prevent="changePage('prev')">이전</a>
        <span>{{ currentPage }}</span>
        <a href="#" class="next" @click.prevent="changePage('next')">다음</a>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchType: "title",
      searchQuery: "",
      posts: [],
      currentPage: 1, // 현재 페이지
      postsPerPage: 10, // 한 페이지에 표시할 게시글 수
      totalPages: 1, // 총 페이지 수
    };
  },
  methods: {
    // 게시물 가져오기
    fetchPosts() {
      fetch(`https://pro1231.duckdns.org/api/postview?page=${this.currentPage}&limit=${this.postsPerPage}`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.posts = data.posts;
            this.totalPages = data.totalPages; // 총 페이지 수 업데이트
          } else {
            alert("게시물을 불러오는데 실패했습니다.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("게시물을 불러오는 중 오류가 발생했습니다.");
        });
    },
    // 게시물 클릭 처리
    handlePostClick(postId) {
      alert('로그인 하지 않은 사용자는 게시글을 열람할 수 없습니다. 로그인 후 이용해주세요.');
    },
    // 검색 버튼 클릭 처리
    handleSearch() {
      alert('게시물 검색 기능은 로그인 후에 사용할 수 있습니다.');
    },
    // 날짜 포맷팅
    formatDate(dateString) {
      const date = new Date(dateString.replace("Z", ""));
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 페이지 이동
    changePage(direction) {
      if (direction === 'prev' && this.currentPage > 1) {
        this.currentPage--;
      } else if (direction === 'next' && this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      this.fetchPosts(); // 새 페이지 데이터 가져오기
    },
    // 페이지 이동
    navigateTo(page) {
      window.location.href = page;
    },
  },
  mounted() {
    this.fetchPosts();
  },
};
</script>